@import "./../../styles/variables.scss";

.dropdown-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translate(0%, -50%);
  width: 0;
  transition: transform 0.3s ease-in-out;
}

.custom-dropdown {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  background: $black;
  border: 2px solid $black;
  border-radius: 8px;
  color: white;
  // 1.1em vertical padding works for when there is only placeholder
  padding: 0.8em 2em 0.8em 1em;
  min-height: 54px;
  font-size: 1em;
  font-weight: 600;
  line-height: 100%;
  transition: all ease-out 0.4s;
  outline: 0 !important;
  position: relative;
  text-align: left;
}

.custom-dropdown:focus,
.custom-dropdown:hover,
.custom-dropdown:active,
.custom-dropdown:focus-visible {
  border: 2px solid #2f2f2f;
  background: #2f2f2f;
  cursor: pointer;
}

.token-icon {
  height: 100%;
}