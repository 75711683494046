@import "./../../styles/variables.scss";

.wallet-details {
  position: fixed;
  z-index: 1;
  right: 1.5rem;
  top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .tooltip-text {
    top: 0;
    left: unset !important;
    right: calc(100% + 1rem);
    transform: translate(0, -20%) !important;
  }

  @media (max-width: map-get($grid-breakpoints, lg)) {
    display: none !important;
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    display: none !important;
  }
}

.connected-dot {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background: $primary-gradient;
}

.referred-dot {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background: $qsr-gradient;
}

.not-connected-dot {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background: $alert-gradient;
}

.pulsating-red {
  box-shadow: 0 0 0 0 rgba($alert, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($alert, 0.8);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba($alert, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($alert, 0);
  }
}

.text-attention-grabber {
  animation: translate-text-gradient 8s infinite ease-in-out;
  background: linear-gradient(90deg,
      $white 40%,
      $alert-gradient-start 45%,
      $alert-gradient-end 50%,
      $alert-gradient-start 55%,
      $white 60%);
  background-size: 1200px;
  font-weight: 700;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  &::before {
    content: "";
    width: 100%;
    height: 300%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    position: absolute;
    animation: translate-text-gradient 8s infinite ease-in-out;
    background: linear-gradient(90deg,
        transparent 40%,
        $alert-gradient-start 45%,
        $alert-gradient-end 50%,
        $alert-gradient-start 55%,
        transparent 60%);
    background-size: 1200px;
    opacity: 0.3;
    filter: blur(28px);
    z-index: -1;
  }

  &::-moz-selection {
    /* Code for Firefox */
    color: $white;
    background: #39af38;
    -webkit-background-clip: $white;
    -webkit-text-fill-color: $white;
    background-clip: unset;
  }

  &::selection {
    color: $white;
    background: #39af38;
    -webkit-background-clip: $white;
    -webkit-text-fill-color: $white;
    background-clip: unset;
  }
}

.background-clip-fix {
  color: $white;
  background: #39af38;
  -webkit-background-clip: $white;
  -webkit-text-fill-color: $white;
  background-clip: unset;
}

@keyframes translate-text-gradient {
  0% {
    background-position-x: 0px;
  }

  50% {
    background-position-x: -800px;
  }

  100% {
    background-position-x: 0px;
  }
}
