@import './../../styles/variables.scss';

.paginator-container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.pagination-page {
  cursor: pointer;
  margin-left: 0.5em;
  margin-right: 0.5em;
  min-width: 24px;
  padding: 8px;
  background: $black;
  text-align: center;
  vertical-align: middle;
  border-radius: 8px;

  &:hover {
    background: $gray;
    text-shadow: 0px 1px 6px rgb(0 0 0);
  }
}

.paginator-empty-space:first-of-type::after {
  content: '...',
}

.paginator-empty-space:last-of-type::after {
  content: '...',
}


.pagination-page.current-page {
  background: $primary;
  box-shadow: 0px 0px 18px rgba(103, 230, 70, 0.65);
  text-shadow: 0px 1px 6px rgb(0 0 0);
}
