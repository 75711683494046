@import './../../styles/variables.scss';

.info-banner {
  position: fixed;
  z-index: 1;
  right: 50%;
  transform: translateX(50%);
  top: .8rem;
  padding: .5rem 2rem;
  font-size: 15px;
  text-align: center;

  background: rgba(38, 38, 38, 0.3);
  border: 1px solid rgba(0, 231, 33, 0.12);
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  border-radius: 100px;
}
