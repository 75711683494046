@import "./../../../styles/variables.scss";

.tabs-container {
  width: 100%;

  .tabs-header {
    width: 100%;
    display: flex;
    border-radius: 8px;
    user-select: none;
    min-width: fit-content;

    .tab-button:nth-of-type(odd) {
      // Left
      border-radius: 8px 0px 0px 8px;
      transform-origin: 100%;
    }

    .tab-button:nth-of-type(even) {
      // Right
      transform-origin: 0%;
      border-radius: 0px 8px 8px 0px;
    }

    .tab-button {
      color: $white;
      cursor: pointer;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      padding: 12px;
      font-weight: 600;
      white-space: nowrap;
      transition: all 0.3s ease-out;
      text-shadow: 1px 2px 14px rgb(0 0 0 / 65%);
      border-radius: 8px;
      background: $black;

      span {
        margin: 0px 12px 0px 12px;
      }

      &:hover {
        background: $gray;
        // background: rgba($primary, 0.2);
      }

      &.active {
        transform: scale(1.1);
        border-radius: 8px 8px 8px 8px;
        background: $primary-gradient-darker;
      }
    }
  }
}
