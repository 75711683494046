@import './../../../styles/variables.scss';

.collectableRewardsCard {
  transition: transform 0.6s ease-out;
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 24px;
  gap: 0.3rem;
  display: flex;
  position: relative;
  border-radius: 8px;
  height: 42px;

  background: $gray;

  &.has-rewards {
    background: $black;

    border: 1px solid rgba(0, 231, 33, 0.3);
    filter: drop-shadow(0px 0px 18px rgba(103, 230, 70, 0.3));
  }

  .rewards-icon {
    font-size: 2rem;
    user-select: none;
  }

  .rewards-button {
    position: absolute;
    height: 100%;
    right: 0px;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    border-radius: 0px 8px 8px 0px;
  }
}